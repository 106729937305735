<template>
  <div class="container">
    <Header />
    <div class="content">
      <div class="banner">
        <img src="../../assets/banner.png" alt="" />
      </div>
      <div class="doubleselectTitle w">
        <div class="w" style="display:flex">
          <div class="title">
            <div class="doubleSelectName">
              北京高途云集教育
            </div>
            <div class="secondrow">
              <div
                class="doubleSelectstate"
              >
                进行中
              </div>
              <!-- <div
                class="doubleSelectstatetwo" >
                未开始
              </div> -->
              <!-- <div class="doubleSelectstatex" v-else>已结束</div> -->
              <div class="each">
                <div>
                  举办时间：2022-06-27 14:00-16:00
                </div>
              </div>
              <div class="each">
                <!-- <img src="../../assets/company.png" alt="" /> -->
                <div>
                  举办企业：<span class="number">北京高途云集教育</span>
                </div>
              </div>
            </div>
          </div>
          <div class="enterassembly">
            <el-popover placement="right"
            >
                <div class="popupstudent">
                  <img style="margin-left:10px;" src="../../assets/ewm.png" alt="" />
                  <p style="text-align:center;">扫描二维码参会</p>
                </div>
              <el-button slot="reference" class="tag">学生参会</el-button>
            </el-popover>
            <div class="tag" >讲师入口</div>
          </div>
        </div>
      </div>
      <div class="invitationLetter w" style="margin-bottom: 58px">
        <div class="invitationLetterName">
          <div class="title">宣讲会邀请函</div>
        </div>
        <!-- <div class="content" v-html="doubleSelectInfo.content"></div> -->
        <div class="content">
          小伙伴们，高途又又又【涨薪资】拉！

         对于很多拿到offer同学来说，真的是人在家中坐，薪资蹭蹭涨啊~

         高中社群运营、高中短期班薪资已经调整到了5000元的无责底薪拉！，综合薪资8-13k哦！

         之前面试其它岗位遗憾落选的同学，可以再次进行报名，参加这两个岗位得面试呀，想不想挑战一下高薪资呢！

         高途校招已经到了尾声拉，高途春招倒计时两周5场，参加不了得同学那到时候想来也没有机会喽，抓住最后得小尾巴呀！

         欢迎大家多多推荐，多多报名参加面试哦~

         【宣讲时间】：6月17日下午13：50

         【宣讲模式】：线上宣讲

          #岗位：高中释放跟多岗位推进就业

         高中学科二讲、短期班、社群持续招聘中，无责底薪5000，高额薪资等你来拿！（又涨薪拉！）

         需求虽然很多，但是还在观望、不敢尝试、那只能错过一次又一次的机会了！

         办公地址：郑州市经开区高途大厦

         预报名链接：https://jinshuju.net/f/E9Vrru

         招聘官选择：02张庆波

         预报名结束记得添加庆波学长得微信，或者告知庆波学长已经预报名，学长这边会给你做好备注，给你在宣讲会得时候发送直播链接，否则可能参加不上宣讲会哦~
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
// vue文件中引入
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'Index',
  data () {
    return {
      doubleSelectInfo: {},
      doubleSelectionId: ''
    }
  },
  components: {
    Header,
    Footer
  },
  filters: {
    timeData: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(-1, 10)
    }
  },

  methods: {

  },
  mounted () {
  }
}
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.w{
  width: 1200px;
  margin: 0 auto;
}
.container {
  min-width: 1180px;
  background: #f8f5f4;
  /deep/.el-dialog{
      position: relative;
      box-shadow: 8px 8px 0px #ffd18c;
      .flexitem{
        display: flex;
        align-items: center;
      }
      .sanjiao{
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top: #fff solid 20px;
        border-left: #fff solid 20px;
        position: absolute;
        bottom: -40px;
        left: 60px;
        z-index: 5;
      }
      .sanjiaotwo{
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top: #ffd18c solid 20px;
        border-left: #ffd18c solid 20px;
        position: absolute;
        bottom: -48px;
        left: 68px;
        z-index: 1;
      }
    }
    /deep/.el-dialog__header{
      position: relative;
    }
    /deep/.el-dialog__title{
      display: inline-block;
      line-height: 40px;
      height: 40px;
      width: 110px;
      text-align: center;
      background-color: #ff9906;
      position: absolute;
      top: -20px;
      left: 20px;
      color: #ffffff;
      box-shadow: 8px 8px 0px #ffd18c;
    }
    /deep/.el-dialog__body{
      font-size: 16px;
      padding-left: 30px;
      color: #222222;
    }
  .content {
    font-size: 14px;
    line-height: 36px;
    .banner {
      width: 100%;
      height: 450px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .doubleselectTitle {
      width: 100%;
      background: #fff;
      padding: 30px 0;
      margin-bottom: 40px;
      .title {
        width: 80%;
        .doubleSelectName {
          font-size: 32px;
          color: #333333;
          margin-bottom: 15px;
        }
        .secondrow {
          display: flex;
          align-items: center;
          .doubleSelectstate {
            width: 64px;
            height: 25px;
            background: #0D92FF;
            border-radius: 2px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
          }
          .doubleSelectstatetwo{
            width: 64px;
            height: 25px;
            background: #ff9f0d;
            border-radius: 2px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
          }
          .doubleSelectstatex {
            width: 64px;
            height: 25px;
            background: #999;
            border-radius: 2px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
          }
          .each {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 47px;
            margin-left: 10px;
            font-size: 14px;
            color: #666666;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            .number {
              font-size: 14px;
            }
          }
        }
      }
      .enterassembly {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        .tag {
          width: 102px;
          height: 38px;
          margin-left: 20px;
          cursor: pointer;
          background: #0D92FF;
          border-radius: 4px;
          font-size: 16px;
          text-align: center;
          line-height: 38px;
          color: #ffffff;
          }
        }
      }
    .attendSchool {
      padding: 40px 0 30px;
      .school {
        width: 320px;
        float: left;
        display: flex;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 20px 30px;
        background: #ffffff;
        border-radius: 4px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .schoolLogo {
          width: 72px;
          height: 72px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .schoolName {
          font-size: 16px;
          color: #333333;
          width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .schoolEnglishName {
          font-size: 14px;
          color: #333333;
          width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }
    }
    .invitationLetter {
      min-height: 450px;
      background: #fff;

      .invitationLetterName {
        display: flex;
        margin-bottom: 22px;
        padding: 20px;

        img {
          width: 21px;
          height: 21px;
        }
        .title {
          font-size: 16px;
          height: 21px;
          line-height: 21px;
          color: #333333;
          margin-left: 10px;
        }
      }
      .content {
        padding: 20px;
        padding-top: 0;
      }
    }
  }
  /deep/.el-dialog {
    // width: 626px;
    // background: #ffffff;
    // box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);

    // /deep/.el-dialog__body {
    //   height: 171px;
    // }
    img {
      padding: 10px;
    }
    .companyTip {
      padding: 10px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #666666;
      margin-left: 9px;
    }
    .dashedline{
      border-bottom: 1px dashed #666666;
    }
    .el-button {
      width: 80px;
      height: 40px;
    }
  }
}
</style>
